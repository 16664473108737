import React, {useState, useEffect, useRef, } from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1,H3, H4, H5, gutter, BodyText1, paddingOneSide, OriginalH4, mainColor} from "./bpresent_style"
import screenShowOffNew from '../../images/bpresentv2/screen-show-off-new.png'
import { useInView } from 'react-intersection-observer'
import {Loader} from "../site_common_components"

export const BPresentIntro = (props) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })
    const imageRef = useRef()

    const effect = useEffect(()=>{
        if (imageRef.current.complete){
            setImageLoaded(true)
        }
    })

    return (
        <React.Fragment>
        <div style={{width: "100vw", height:"100vh", position: "absolute", display:"flex", justifyContent: "center", alignItems:"center", left:0, top:0}}>
            <Loader color={mainColor} style={{position:"absolute", opacity: imageLoaded ? 0 : 1}}></Loader>
            </div>
        <div ref={ref} style={{ transition: "opacity 0.35s ease-in, top 0.4s ease-in", opacity: (inView && imageLoaded )? 1 : 0, position:"relative", top: (inView && imageLoaded) ? "0": "30px"}}>
        <Row>
                <Col sm={{size: 10, offset:1}} style={{display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}}>
                    {/* { imageLoaded ? <React.Fragment></React.Fragment>: */}
                    {/* <Loader color={mainColor} style={{position:"absolute", top:"20vh", justifySelf:"center", alignSelf:"center", opacity: imageLoaded ? 0 : 1}}></Loader> */}
                     {/* } */}

                    <BPresentH1>
                        B-Present
                    </BPresentH1>

                    <OriginalH4 showDot={false} style={{marginTop: "0.8rem", marginBottom:"2rem"}}>
                        A social game combined with big data to help gift buyers’ choose personalized gifts for their close relations
                    </OriginalH4>

                    <Subtitle1 style={{marginTop:"16px", fontWeight: 300}}>
                        Collaborated with Yi He, Xiuxiu Yuan, Josiah Mangiameli
                    </Subtitle1>

                    <Subtitle1 style={{marginTop:"8px", fontWeight: 300}}>
                        Aug. 2018 - Dec. 2018
                    </Subtitle1>

                    <Subtitle1 style={{marginTop:"8px", fontWeight: 300, fontStyle:"italic"}}>
                        Leading user evaluation, ideation and the interface improvement 
                    </Subtitle1>

                    {/* Put the image here */}
                    <img ref={imageRef} src={screenShowOffNew} style={{width:"80%", alignSelf:"center", marginTop:"1rem"}} onLoad={()=>{
                        // console.log("hello")
                        setImageLoaded(true)
                    }} 
                        >
                    </img>    
                </Col>  
        </Row>
        </div>
        </React.Fragment>
    )
}


