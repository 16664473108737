import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import {
  BPresentH1,
  Subtitle1,
  H4,
  H5,
  gutter,
  BodyText1,
  BodyText2,
  paddingOneSide,
  styleForColumn,
  Subtitle1OpenSansWithLightColor,
  Caption,
} from './bpresent_style'
import { useInView } from 'react-intersection-observer'
import teamHotpotIdeation from '../../images/bpresentv2/team_hotpot_ideation.jpg'
import { ZoomableImageWithModal } from './bpresent_common_component'
import conceptEvaluation from '../../images/bpresentv2/User_Feedback_Design_alternative.png'
import userFlow from '../../images/bpresentv2/user_flow.png'
import profileRelatedFeature from '../../images/bpresentv2/Profile-related_flow.png'
import styleQuizFeature from '../../images/bpresentv2/Style_quiz_flow.png'
import forumFlowFeature from '../../images/bpresentv2/Forum_flow.png'
import giftLabImage from '../../images/bpresentv2/Gift_Lab_flow.png'
import giftSuggestionImage from '../../images/bpresentv2/Gift_suggestion_flow.png'


const featureSets = [
  {
    subtitle: 'Profile Set-up',
    explanation:
      "Each user will build their own profile to ease others’ effort to send them best gifts. The profile asks about the user's preferences, hobbies and personal cloth size. Some information can be imported from the social network.  This feature hopes to give gift recipients a place to communicate their preference. At the same time, it allows gift buyers to obtain personal information without worrying about violating recipients' privacy.",
    image: profileRelatedFeature,
  },
  {
    subtitle: 'Understand your own style through a Style Quiz',
    explanation:
      "If users don't know their styles for their profile, they have choose to take a style quiz. The system will help decide on their style. This will help gift buyers get a better recommendation. With this feature, we want to save user some time.",
    image: styleQuizFeature,
  },
  {
    subtitle:"Get to know gift recipients through Gift Lab with fun",
    explanation:"In this simple game, gift buyers visualize their ideas on the recipients’ photos and recipients give each idea feedback with emoji. Each feedback is counted as points for one gift buyer to compare with one another. We want this feature to add fun to the process, and at the same time, elicit social connection.",
    image:giftLabImage,
  },
  {
    subtitle:"Get to know gift recipient through a discussion forum",
    explanation:"A forum where users can raise any questions about gifts to recipients or mutual friends they have.",
    image:forumFlowFeature,
  },  
  {
    subtitle:"Receive recommendations from the app through Suggestion list",
    explanation: "This feature is a list generated by AI algorithms based on profile, gaming record, and discussion forum. It allows users to quickly compare multiple items and identify the fitting ones right away. ",
    image: giftSuggestionImage,
  }
]

const FeatureRow2 = ({ subtitle, explanation, image }) => {
  return (
    <React.Fragment>
      <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
        <Subtitle1>{subtitle}</Subtitle1>
      </Col>

      <Col md={{size: 7}}/>
          
      {/* New Row */}
      <Col md={{ size: 6, offset:1 }} style={styleForColumn}>
        <BodyText1>{explanation}</BodyText1>
      </Col>

      <Col md={1} />
      {/* New Row */}
      <Col md={{ size: 6, offset: 3 }} >
        {/* Put the image here */}
        <img src={image}></img>
      </Col>

      <Col md={1} />
    </React.Fragment>
  )
}

const FeatureRow = ({subtitle, explanation, image}) => (
    <React.Fragment>
       <Col md={{ size: 4, offset: 1 }} style={{...styleForColumn, marginTop:"1rem"}}>
            <H5>{subtitle}</H5>
        </Col>

        <Col md={7}></Col>

        {/* New Row */}
        <Col md={{size:4, offset: 1}} style={{...styleForColumn, marginTop:"2rem"}}>
            <BodyText2 style={{lineHeight:"200%", color:"#696969"}}>{explanation}</BodyText2>
        </Col>

        <Col md={{size:6}} style={{...styleForColumn, marginTop: "2rem"}}>
            <ZoomableImageWithModal image={image} header={subtitle}></ZoomableImageWithModal>
        </Col>
        <Col md={1}></Col>

    </React.Fragment>
)
    

export const BPresentHifi = () => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    // root:""
  })

  return (
    <React.Fragment>
      <div ref={ref} style={{ transition: 'opacity 0.2s ease-in',
          opacity: inView ? 1 : 0,}}>
      <Row
        style={{
          // position: 'sticky',
          // top: '40px',
          zIndex: 3,
          background: 'transparent',
        }}
      >
        <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
          <H4>
            High-fidelity prototype
          </H4>
        </Col>
      </Row>

      <Row style={{ marginTop: '3rem', position: 'relative' }}>
        
        {featureSets.map(feature => (
            <FeatureRow {...feature}/>
        ))}

        {/* <div
          style={{
            height: '70%',
            width: '10px',
            background: 'transparent',
            position: 'absolute',
            top: '-20px',
          }}
          ref={ref}
        /> */}
      </Row>
      </div>
    </React.Fragment>
  )
}
