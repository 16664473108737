import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./bpresent_style"
import {ZoomableImageWithModal } from "./bpresent_common_component";
import gift from '../../images/bpresentv2/gift.png'
import { useInView } from 'react-intersection-observer'
import physicalPrototype from "../../images/bpresentv2/Physical-prototype.png"
import lowfiPrototype from "../../images/bpresentv2/Wireframe.png"

export const BPResentPrototype = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    const [ref2, inView2] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })
    
    return (
        <React.Fragment>
            <div ref={ref} style={{ transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0}}>
            <Row style={{
                top:"40px", zIndex:3, background:"transparent", 
            }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4>
                        Low-fidelity Prototype
                    </H4>
                </Col>
            </Row>

            <Row style={{position:"relative", marginTop:"3rem"}}>
                <Col md={{size: 4, offset: 1}} style={{...styleForColumn,}}>
                    <img src={physicalPrototype} style={{alignSelf:"flex-end", borderRadius:"10px"}}/>
                </Col>

                <Col md={{size: 6}} style={{...styleForColumn}}>
                    <BodyText1>
                        We want to validate whether the gift lab interaction is fun and useful perceived by users. So in two days, we created a physical <strong> Wizard of Oz </strong> interactive prototype by printing photos, slicing through foam boards and cutting out images and emojis. We presented our prototype to the class and collected feedback while they played with it. Our review were mostly <strong> positive, feeling fun and useful </strong> for determining their friends’ and close relations' preference
                    </BodyText1>
                </Col>

                <Col md={1}/>

                {/* New Row */}
                <Col md={{size: 4, offset: 1}} style={{...styleForColumn, marginTop:"2rem", padding:0}}>
                    <ZoomableImageWithModal image={lowfiPrototype} header={"Wireframe"} imgStyle={{borderRadius: "15px"}}/>
                </Col>

                <Col md={{size: 6}} style={styleForColumn}>
                    <BodyText1 style={{marginTop:"2rem"}}>
                        We also created a simple sketch and wireframe to determine the information architecture of our design. 
                    </BodyText1>
                </Col>

                <Col md={1}/>
                
                {/* New Row */}

                {/* <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/> */}
            </Row>
            </div>
        </React.Fragment>
    )
}