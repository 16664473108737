import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import LazyLoad from 'react-lazyload';
import {
  BPresentH1,
  Subtitle1,
  H4,
  gutter,
  BodyText1,
  paddingOneSide,
  styleForColumn,
  H5,
  Subtitle1OpenSansWithLightColor,
  BodyTextWithLightColor,
} from './bpresent_style'
import { ZoomableImageWithModal } from './bpresent_common_component'
import persona from '../../images/bpresentv2/new_persona.png'
import researchPhoto from '../../images/bpresentv2/research-cropped.jpg'
import { useInView } from 'react-intersection-observer'
import giftBuyerSetUpGame from '../../images/bpresentv2/Gift-buyer-set-up-game.mp4'
import giftBuyerPlayGame from '../../images/bpresentv2/Gift-buyer-play-game.mp4'
import giftRecipientRateIdea from '../../images/bpresentv2/Gift-recipient-rate-idea.mp4'
import giftRecipientSetUp from '../../images/bpresentv2/Gift-recipient-set-up.mp4'
import { Caption } from '../text_style'
import removeFeature from '../../images/bpresentv2/RemoveFeature.jpg'

import TabChange from '../../images/bpresentv2/tab-screen-change.jpg'
import GameUIChange from '../../images/bpresentv2/ui-screen-change.jpg'

import buyerSetupGiftPage from '../../images/bpresentv2/ImprovementScreens/GiftBuyerSetup/GiftPage.png'
import buyerSetupFriendsTab from '../../images/bpresentv2/ImprovementScreens/GiftBuyerSetup/FriendsTab.png'
import buyerSetupInvitationInfo from '../../images/bpresentv2/ImprovementScreens/GiftBuyerSetup/InvitationInfo.png'

import recipientSetUpAddHint from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/AddHint.png'
import recipientSetUpGameIntro from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/GameIntro.png'
import recipientSetUpGiftTab from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/GiftTab.png'
import recipientSetUpInvitationCard from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/InvitationCard.png'
import recipientSetUpSetupHint from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/SetupHint.png'
import recipientSetUpUploadPage from '../../images/bpresentv2/ImprovementScreens/GiftRecipientSetup/UploadPage.png'

import buyerPlayGameReceive from '../../images/bpresentv2/ImprovementScreens/PlayGame/PlayGameScreen1-Receive.png'
import buyerPlayGameEntryCard from '../../images/bpresentv2/ImprovementScreens/PlayGame/PlayGameScreen2-EntryCard.png'
import buyerPlayGameBaseInterface from '../../images/bpresentv2/ImprovementScreens/PlayGame/PlayGameScreen3-BaseInterface.png'
import buyerPlayGameReadingNotes from '../../images/bpresentv2/ImprovementScreens/PlayGame/PlayGameScreen4-Reading-notes.png'
import buyerPlayGameFinalPage from '../../images/bpresentv2/ImprovementScreens/PlayGame/PlayGameScreen5-Finalpage.png'

import recipientRateGameEntryPage from '../../images/bpresentv2/ImprovementScreens/RateGame/RateGameScreen1-EntryPage.png'
import recipientRateGameBaseInterface from '../../images/bpresentv2/ImprovementScreens/RateGame/RateGameScreens-BaseInterface.jpg'
import recipientRateGameChooseAFace from '../../images/bpresentv2/ImprovementScreens/RateGame/RateGameScreens-ChooseAFace.png'
import recipientRateGameLeaderboard from '../../images/bpresentv2/ImprovementScreens/RateGame/RateGameScreens-Leaderboard.png'

const recipientRateGameInfo = [
  {
    text:
      'I also designed a simple card for the entry for rating the game to give user a better sense of the next steps.',
    imageSrc: recipientRateGameEntryPage,
  },
  {
    text:
      'The instruction of how to rate the game is added. The user can switch between player ideas through clicking the headshots on the bottom.',
    imageSrc: recipientRateGameBaseInterface,
  },
  {
    text:
      'The player’s score for the persons will be instant updated after the user (gift recipient) clicked on the emojis to give a clear sense of how scores work',
    imageSrc: recipientRateGameChooseAFace,
  },
  {
    text:
      'A newly designed leaderboard design gives the user a better sense of who wins and their ideas',
    imageSrc: recipientRateGameLeaderboard,
  },
]

const buyerSetupScreensInfo = [
  {
    text: 'The “friends” tab now has a quick access to find a gift',
    imageSrc: buyerSetupGiftPage,
  },
  {
    text:
      'The gift lab is visually empathized while the user can still choose to generate search result',
    imageSrc: buyerSetupFriendsTab,
  },
  {
    text:
      'The user can edit the list of their friends through different interactions like long press',
    imageSrc: buyerSetupInvitationInfo,
  },
]

const recipientSetUpInfo = [
  {
    text:
      'The “gift” tab now tracks the gift lab process and serve as a quick access to any action.',
    imageSrc: recipientSetUpGiftTab,
  },
  {
    text:
      'The invitation card is redesigned to emphasize provide clear information and instruction.',
    imageSrc: recipientSetUpInvitationCard,
  },
  {
    text: 'A newly designed page to introduce how to play the game.',
    imageSrc: recipientSetUpGameIntro,
  },
  {
    text: 'Added new instructions to Upload Photos page.',
    imageSrc: recipientSetUpUploadPage,
  },
  {
    text: 'Setting up hint now has a better instruction that guides the user.',
    imageSrc: recipientSetUpSetupHint,
  },
  {
    text: 'Page to add the hint.',
    imageSrc: recipientSetUpAddHint,
  },
]

const giftBuyerPlayGameInfo = [
  {
    text:
      'Notification serves as an access point to any action. The user can also follow the badge on the gift to access the game',
    imageSrc: buyerPlayGameReceive,
  },
  {
    text:
      "The entry card gives user's a headup in what game they are going to encounter",
    imageSrc: buyerPlayGameEntryCard,
  },
  {
    text:
      'The interface is changed to better display the information a gift buyer needs to make a reasonable decision',
    imageSrc: buyerPlayGameBaseInterface,
  },
  {
    text:
      'The reading hint entry page is added to prevent user from mistakenly reading hints which has negative effect.',
    imageSrc: buyerPlayGameReadingNotes,
  },
  {
    text:
      'A leave page is designed to inform user the end of the game and future action.',
    imageSrc: buyerPlayGameFinalPage,
  },
]

const changeOpacityAnimation = inView => ({
  transition: 'opacity 0.2s ease-in',
  opacity: inView ? 1 : 0,
})

const ImprovedScreensShowcase = props => {
  return (
    <React.Fragment>
      <Col
        md={4}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '2rem',
        }}
      >

        <LazyLoad height={200}>
        <img 
          src={props.imageSrc}
          style={{
            height: 'auto',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'rgba(151,151,151,0.2)',
          }}
        ></img>
        </LazyLoad>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              height: '6px',
              width: '8px',
              marginRight: '8px',
              backgroundColor: '#D8D8D8',
              borderRadius: '6px',
            }}
          />

          <Caption>{props.text}</Caption>
        </div>
      </Col>
    </React.Fragment>
  )
}

export const BPresentImprovement = () => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.1,
    // root:""
  })

  const [ref2, inView2] = useInView({
    /* Optional options */
    threshold: 0.1,
    // root:""
  })

  const [ref3, inView3] = useInView({
    /* Optional options */
    threshold: 0.1,
    // root:""
  })

  return (
    <React.Fragment>
      <div ref={ref} style={{ ...changeOpacityAnimation(inView) }}>
        <Row
          style={{
            // position: 'sticky',
            zIndex: 3,
            background: 'transparent',
            transition: 'opacity 0.2s ease-in',
            // opacity: inView ? 1 : 0,
          }}
        >
          <Col md={{ size: 10, offset: 1 }} style={styleForColumn}>
            <H4 >Improvement</H4>
            <BodyText1 style={{ marginTop:"1rem", marginBottom: '300px' }}>
              After the evaluation, I made improvement in the overall design of
              our app based on the feedback we received.
            </BodyText1>
          </Col>
        </Row>
      </div>
        
      <div ref={ref2} style={{...changeOpacityAnimation(inView2)}}>
      <Row>
        <Col md={{ size: 10, offset: 1 }} style={styleForColumn}>
            <H5 style={{ marginBottom: '3rem' }}>
              {' '}
              Distilled features that fit the purpose{' '}
            </H5>

            <img
              src={removeFeature}
              style={{ width: '80%', alignSelf: 'center' }}
            ></img>

            <BodyTextWithLightColor style={{ marginBottom: '4rem' }}>
              In the improvement, to address the feedback on overcomplicated
              features, I <strong>removed the feature of social forum</strong>,
              the least relevant one to our ideas and goals. I put more empathize
              on how different part of the app works with the games.
            </BodyTextWithLightColor>

            {/* Improvement 2 */}
            <H5 style={{ marginBottom: '3rem' }}>
              {' '}
              Reorganized the user flow and information architecture{' '}
            </H5>

            <img
              src={TabChange}
              style={{ width: '80%', alignSelf: 'center' }}
            ></img>

            <BodyTextWithLightColor style={{ marginBottom: '4rem' }}>
              To address the problem of confusing information architecture, I
              redesigned the screens connecting each feature to better fit user
              flow. Most notably, I <strong> reorganized tabs </strong> to ensure
              each tab has a functionality compared to others.
            </BodyTextWithLightColor>

            {/* Improvement 3 */}
            <H5 style={{ marginBottom: '3rem' }}>
              {' '}
              Aimed for a more user-friendly game UI{' '}
            </H5>

            <img
              src={GameUIChange}
              style={{ width: '80%', alignSelf: 'center' }}
            ></img>

            <BodyTextWithLightColor style={{ marginBottom: '300px' }}>
              This improvement iteration also puts much{' '}
              <strong> empathized on UI and instructions </strong> of the Gift Lab
              so that users shall be able to figure out the way to play the games
              and their progress in the game much more quickly without help. I
              refined the writing of instructions, creating better layout and
              transition animations to give user clear instructions for game steps
              .
            </BodyTextWithLightColor>
          </Col>
      </Row>

      </div>
      <div ref={ref3} style={{...changeOpacityAnimation(inView3)}}>
      <Row style={{ marginTop: '1.5rem', position: 'relative' }}>
        <Col md={{ size: 10, offset: 1 }}>
          <BodyTextWithLightColor>
              Below are refined user interactions and related screens
          </BodyTextWithLightColor>
        </Col>

        {/* New Row */}
        <Col md={{ size: 10, offset: 1 }}>
          <H5 style={{ marginTop: '2rem' }}>
            Gift buyers send invitation to use Gift Labs{' '}
          </H5>
        </Col>
        <Col md={1}></Col>

        {/* New Row */}
        <Col
          md={{ size: 3, offset: 1 }}
          style={{ ...styleForColumn, alignItems: 'center', marginTop: '2rem' }}
        >
          <video
            src={giftBuyerSetUpGame}
            loop
            muted
            style={{
              boxShadow: "0 4px 30px rgba(0,0,0,0.2)",
              width: '100%',
              borderStyle: 'solid',
              borderColor: 'rgba(151,151,151,0.2)',
              borderWidth: '1px',
            }}
            controls
          />
        </Col>

        <Col
          md={{ size: 6, offset: 1 }}
          style={{ ...styleForColumn, flexWrap: 'wrap', marginTop: '2rem' }}
        >
          <Row>
            {buyerSetupScreensInfo.map(info => (
              <ImprovedScreensShowcase {...info} />
            ))}
          </Row>
        </Col>

        {/* Second Section */}
        <Col md={{ size: 10, offset: 1 }}>
          <H5 style={{ marginTop: '4rem' }}>
            {' '}
            Gift recipients set up the game{' '}
          </H5>
        </Col>

        {/* New Row */}
        <Col
          md={{ size: 3, offset: 1 }}
          style={{ ...styleForColumn, alignItems: 'center', marginTop: '2rem' }}
        >
          <video
            src={giftRecipientSetUp}
            loop
            muted
            style={{
              boxShadow: "0 4px 30px rgba(0,0,0,0.2)",
              width: '100%',
              borderStyle: 'solid',
              borderColor: 'rgba(151,151,151,0.2)',
              borderWidth: '1px',
            }}
            controls
          />
        </Col>

        <Col
          md={{ size: 6, offset: 1 }}
          style={{ ...styleForColumn, flexWrap: 'wrap', marginTop: '2rem' }}
        >
          <Row>
            {recipientSetUpInfo.map(info => (
              <ImprovedScreensShowcase {...info} />
            ))}
          </Row>
        </Col>

        {/* Third Section */}
        <Col md={{ size: 10, offset: 1 }}>
          <H5 style={{ marginTop: '4rem' }}> Gift buyers play the games </H5>
        </Col>

        {/* New Row */}
        <Col
          md={{ size: 3, offset: 1 }}
          style={{ ...styleForColumn, alignItems: 'center', marginTop: '2rem' }}
        >
          <video
            src={giftBuyerPlayGame}
            loop
            muted
            style={{
              boxShadow: "0 4px 30px rgba(0,0,0,0.2)",
              width: '100%',
              borderStyle: 'solid',
              borderColor: 'rgba(151,151,151,0.2)',
              borderWidth: '1px',
            }}
            controls
          />
        </Col>

        <Col
          md={{ size: 6, offset: 1 }}
          style={{ ...styleForColumn, flexWrap: 'wrap', marginTop: '2rem' }}
        >
          <Row>
            {giftBuyerPlayGameInfo.map(info => (
              <ImprovedScreensShowcase {...info} />
            ))}
          </Row>
        </Col>

        {/* Fourth Section */}
        <Col md={{ size: 10, offset: 1 }}>
          <H5 style={{ marginTop: '4rem' }}> Gift recipients rate the game </H5>
        </Col>

        {/* New Row */}
        <Col
          md={{ size: 3, offset: 1 }}
          style={{ ...styleForColumn, alignItems: 'center', marginTop: '2rem' }}
        >
          <video
            src={giftRecipientRateIdea}
            loop
            muted
            style={{
              boxShadow: "0 4px 30px rgba(0,0,0,0.2)",
              width: '100%',
              borderStyle: 'solid',
              borderColor: 'rgba(151,151,151,0.2)',
              borderWidth: '1px',
            }}
            controls
          />
        </Col>

        <Col
          md={{ size: 6, offset: 1}}
          style={{ ...styleForColumn, flexWrap: 'wrap', marginTop: '2rem' }}
        >
          <Row>
            {recipientRateGameInfo.map(info => (
              <ImprovedScreensShowcase {...info} />
            ))}
          </Row>
        </Col>

        {/* New Row */}
        {/* <Col md={{size:5, offset: 1}} style={{...styleForColumn, alignItems:"center", marginTop:"2rem"}}>
            <video src={giftBuyerSetUpGame} loop muted style={{width:"70%"}} controls/>
            <Caption style={{marginTop:"2rem"}}>Gift buyers send invitation to use Gift Labs</Caption>
        </Col>

        <Col md={{size:5}} style={{...styleForColumn, alignItems:"center", marginTop:"2rem"}}>
            <video src={giftRecipientSetUp} loop muted style={{width:"70%"}} controls/>
            <Caption style={{marginTop:"2rem"}}>Gift recipients set up the game</Caption>
        </Col>

        <Col md={1}/> */}

        {/* New Row */}
        {/* <Col md={{size:5, offset: 1}} style={{...styleForColumn, alignItems:"center", marginTop:"2rem"}}>
            <video src={giftBuyerPlayGame} loop muted style={{width:"70%"}} controls/>
            <Caption style={{marginTop:"2rem"}}>Gift buyers play the games</Caption>
        </Col>

        <Col md={{size:5}} style={{...styleForColumn, alignItems:"center", marginTop:"2rem"}}>
            <video src={giftRecipientRateIdea} loop muted style={{width:"70%"}} controls/>
            <Caption style={{marginTop:"2rem"}}>Gift recipients rate the game</Caption>
        </Col>

        <Col md={1}/> */}
      </Row>
      </div>
    </React.Fragment>
  )
}
