import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import {
  BPresentH1,
  Subtitle1,
  H4,
  gutter,
  BodyText1,
  BodyText2,
  paddingOneSide,
  styleForColumn,
  Subtitle1OpenSansWithLightColor,
  Caption,
  BodyTextWithLightColor,
  H5,
} from './bpresent_style'
import { useInView } from 'react-intersection-observer'
import teamHotpotIdeation from '../../images/bpresentv2/team_hotpot_ideation.jpg'
import teamHotpotIdeation2 from '../../images/bpresentv2/IdeationImg2.png'
import teamHotpotIdeation3 from '../../images/bpresentv2/IdeationImg3.png'
import {
  ZoomableImageWithModal,
  AnimatedTextSpan,
} from './bpresent_common_component'
import designAlternative1 from '../../images/bpresentv2/design-alternative-1.png'
import designAlternative2 from '../../images/bpresentv2/design-alternative-2.png'
import designAlternative3 from '../../images/bpresentv2/design-alternative-3.png'
import conceptEvaluation from '../../images/bpresentv2/User_Feedback_Design_alternative.png'
import userFlow from '../../images/bpresentv2/user_flow.png'
import TrackVisibility from 'react-on-screen'
import feedback from '../../images/bpresentv2/Feedback_synthesize_new.jpg'
import giftLabIdea from '../../images/bpresentv2/GiftLabIdea.jpg'

const opacityChangeAnimation = inView => ({
  transition: 'opacity 0.2s ease-in',
  opacity: inView ? 1 : 0,
})

export const BPresentIdeation = () => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.2,
    // root:""
  })

  const [ref2, inView2] = useInView({
    /* Optional options */
    threshold: 0.1,
    // root:""
  })

  const [ref3, inView3] = useInView({
    /* Optional options */
    threshold: 0.1,
    // root:""
  })
  return (
    <React.Fragment>
      <div ref={ref} style={{ ...opacityChangeAnimation(inView) }}>
        <Row>
          <Col md={{ size: 6, offset: 1 }} style={{ ...styleForColumn }}>
            <img src={teamHotpotIdeation} style={{ borderRadius: '8px' }}></img>
          </Col>

          <Col
            md={{ size: 4 }}
            style={{
              ...styleForColumn,
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <img src={teamHotpotIdeation2} style={{ width: '100%' }}></img>

            <img src={teamHotpotIdeation3} style={{ width: '100%' }}></img>
          </Col>
        </Row>

        <Row
          style={{
            // position: 'sticky',
            // top: '40px',
            zIndex: 3,
            background: 'transparent',
            marginTop: '80px',
          }}
        >
          <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
            <H4>Ideation</H4>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: '1.5rem',
            position: 'relative',
            marginBottom: '300px',
            ...opacityChangeAnimation(inView),
          }}
        >
          <Col md={{ size: 10, offset: 1 }} style={styleForColumn}>
            <BodyText1>
              For the ideation, to gather as many ideas as possible, we hosted a
              brainstorm session. Each of us has 30 minutes working time to draw
              as many ideas as possible based on our persona and user journey
              map. After that, each of us presented our ideas and then we voted
              into
              <strong> three different ideas </strong>.
            </BodyText1>
          </Col>
        </Row>
      </div>
      <div ref={ref2} style={{ ...opacityChangeAnimation(inView2) }}>
        <Row style={{ marginBottom: '300px' }}>
          <Col md={{ size: 3, offset: 1 }}>
            <ZoomableImageWithModal
              image={designAlternative1}
              header={'Idea 1: The Profile Builder'}
              style={{ minHeight: '340px', padding: 0 }}
            />
          </Col>

          <Col
            md={{ size: 6, offset: 1 }}
            style={{ ...styleForColumn, justifyContent: 'center' }}
          >
            <H5 style={{ marginBottom: '2rem' }}>
              Idea 1: Accumulate the understanding with a mobile profile builder
            </H5>

            <BodyTextWithLightColor>
              This “profile builder” idea helps users to record details of their
              close relations’ needs and wants in their daily life interaction
              by building a guided profile for them. Thus, users may quickly
              figure out a good gifts based on their profiles, thus leading to
              less stressed.
            </BodyTextWithLightColor>
          </Col>

          <Col md={{ size: 1 }}></Col>

          {/* Row 2 */}
          <Col md={{ size: 3, offset: 1 }}>
            <ZoomableImageWithModal
              image={designAlternative2}
              header={'Idea 1: The Profile Builder'}
              style={{ minHeight: '360px', padding: 0 }}
            />
          </Col>

          <Col
            md={{ size: 6, offset: 1 }}
            style={{ ...styleForColumn, justifyContent: 'center' }}
          >
            <H5 style={{ marginBottom: '2rem' }}>
              Idea 2: Ask help from friends on web social media
            </H5>

            <BodyTextWithLightColor>
              This idea features a social media board where users can add those
              friends and discussed or voted on the gift ideas. It may enable
              users to seek feedback and inspiration from their friends or
              common friends with gift recipients’ on their gift choices. Also,
              the social support shall reduce the stress of this process.
            </BodyTextWithLightColor>
          </Col>
          <Col md={{ size: 1 }}></Col>

          {/* Row 3 */}
          <Col md={{ size: 3, offset: 1 }}>
            <ZoomableImageWithModal
              image={designAlternative3}
              header={'Idea 1: The Profile Builder'}
              style={{ minHeight: '360px', padding: 0 }}
            />
          </Col>

          <Col
            md={{ size: 6, offset: 1 }}
            style={{ ...styleForColumn, justifyContent: 'center' }}
          >
            <H5 style={{ marginBottom: '2rem' }}>
              Idea 3: Deepen mutual understanding through VR game
            </H5>

            <BodyTextWithLightColor>
              This idea features a VR space where the gift buyer can get
              inspired through playing a game to deepen his understanding of
              gift recipient’s want and need with the help of a game. This
              face-to-face game also makes of the process of thinking about gift
              ideas more fun and less stressful
            </BodyTextWithLightColor>
          </Col>
        </Row>
      </div>

      <div ref={ref3} style={{ ...opacityChangeAnimation(inView3) }}>
        <Row style={{ marginBottom: '100px' }}>
          <Col md={{ size: 5, offset: 1 }}>
            <H5 style={{ marginBottom: '3rem' }}>Evaluate our concepts</H5>
          </Col>

          <Col md={{ size: 6 }} />

          <Col md={{ size: 5, offset: 1 }}>
            <BodyText1>
              Thus, we conducted over 10 user evaluations on those alternatives
              to understand gift buyer's expectations to the solution. Users
              agreed that all of those options achieved the goal of the design.
              They valued the organization and flexibility in the first
              solution, social support for the second and the fun for the last
              idea. At the same time, our future solution needs to reduce their
              concerns on privacy and the feeling of cheating. We decided to
              come up with a new idea combined with all we learnt.
            </BodyText1>
          </Col>

          <Col md={{ size: 5 }} style={styleForColumn}>
            <ZoomableImageWithModal
              image={feedback}
              header={'Feedback synthsization'}
            ></ZoomableImageWithModal>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 5, offset: 1 }}>
            <H5 style={{ marginBottom: '3rem' }}>A new idea — Gift Lab</H5>
          </Col>

          <Col md={{ size: 6 }} />

          <Col md={{ size: 5, offset: 1 }}>
            <BodyText1>
              We saw the potential to fit all of those values in a game idea
              called Gift Lab. In this game, gift buyers can post the icon or
              photo on gift recipients’ photos to guess their preference. At the
              end, the gift recipients will rate those ideas. Based on the
              feedback, gift buyers can have a better idea of what they want.
              This idea provides users with both the context to inspire gift
              buyer and social interaction that can reduce the stress.
            </BodyText1>
          </Col>

          <Col md={{ size: 5 }}>
            <ZoomableImageWithModal
              image={giftLabIdea}
              header={'Feedback synthsization'}
            ></ZoomableImageWithModal>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 5, offset: 1 }}>
            <H5 style={{ marginBottom: '3rem' }}>
              Define user experience through user flow
            </H5>
          </Col>

          <Col md={{ size: 6 }} />

          <Col md={{ size: 5, offset: 1 }}>
            <BodyText1>
              Around the core mechanism of the game, we thought about how users
              will enter the game, and what information they require to to play
              the game, and what information they need to better interpret the
              results and make decisions. We add another 4 features to
              strengthen the design of those features.
              <br />
              - Profile builder
              <br />
              - Style quiz
              <br />
              - Forum
              <br />
              - Gift recommendation list
              <br />
            </BodyText1>
          </Col>

          <Col md={{ size: 5 }}>
            <ZoomableImageWithModal
              image={userFlow}
              header={'User flow'}
            ></ZoomableImageWithModal>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

// {/* <Row style={{ marginTop: '1.5rem', position: 'relative' }}>
//         <Col md={1}></Col>

//         {/* New Row */}
//         <Col
//           md={{ size: 10, offset: 1 }}
//           style={{
//             ...styleForColumn,
//             flexDirection: 'row',
//             justifyContent: 'space-evenly',
//           }}
//         >
//           Vertically lay out the space
//           the problem I'm having is how to ensure the same height
//           I can hardset a height: 360

//           Another way to do this job is to crop the image to be literally the same size
//           What I can do is to copy those three, and use the preview to crop it
//           What is the size I'm looking. Probably the aspect ratio similar to the first one
//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <ZoomableImageWithModal
//               image={designAlternative1}
//               header={'Idea 1: The Profile Builder'}
//               style={{ minHeight: '360px' }}
//             />
//             <Caption> Idea 1: The profile builder </Caption>
//           </div>

//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <ZoomableImageWithModal
//               image={designAlternative2}
//               header={'Idea 2: Social media'}
//               style={{ minHeight: '360px' }}
//             />
//             <Caption> Idea 2: Social media </Caption>
//           </div>

//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <ZoomableImageWithModal
//               image={designAlternative3}
//               header={'Idea 3: VR game'}
//               style={{ minHheight: '360px' }}
//             />
//             <Caption> Idea 3: VR game </Caption>
//           </div>
//         </Col>

//         <Col
//           md={{ size: 6, offset: 5 }}
//           style={{ ...styleForColumn, marginTop: '3rem' }}
//         >
//           <Subtitle1OpenSansWithLightColor style={{ marginBottom: '1rem' }}>
//             Idea 1: The Profile Builder
//           </Subtitle1OpenSansWithLightColor>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             This “profile builder” idea helps users to record details of their
//             close relations’ needs and wants in their daily life interaction by
//             building a guided profile for them. Thus, users may quickly figure
//             out a good gifts based on their profiles, thus leading to less
//             stressed.
//           </BodyText1>

//           <Subtitle1OpenSansWithLightColor style={{ marginBottom: '1rem' }}>
//             Idea 2: Social Media
//           </Subtitle1OpenSansWithLightColor>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             This idea features a social media board where users can add those
//             friends and discussed or voted on the gift ideas. It may enable
//             users to seek feedback and inspiration from their friends or common
//             friends with gift recipients’ on their gift choices. Also, the
//             social support shall reduce the stress of this process.
//           </BodyText1>

//           <Subtitle1OpenSansWithLightColor style={{ marginBottom: '1rem' }}>
//             Idea 3: VR Game
//           </Subtitle1OpenSansWithLightColor>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             This idea features a VR space where the gift buyer can get inspired
//             through playing a game to deepen his understanding of gift
//             recipient’s want and need with the help of a game. This face-to-face
//             game also makes of the process of thinking about gift ideas more fun
//             and less stressful
//           </BodyText1>
//         </Col>
//         <Col md={1}></Col>

//         <Col
//           md={{ size: 6, offset: 5 }}
//           style={{ ...styleForColumn, marginTop: '2rem' }}
//         >
//           <Subtitle1OpenSansWithLightColor>
//             Concept evaluation
//           </Subtitle1OpenSansWithLightColor>
//         </Col>

//         <Col
//           md={{ size: 4, offset: 1 }}
//           style={{ ...styleForColumn, marginTop: '1rem' }}
//         >
//           <ZoomableImageWithModal image={conceptEvaluation} />
//         </Col>

//         <Col md={{ size: 6 }} style={{ ...styleForColumn, marginTop: '1rem' }}>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             Those design alternatives provided us with more opportunities to
//             understand gift buyer's expectations to a solution. Thus, we
//             conducted over 10 user evaluations on those three. Users agreed that
//             all of those options achieved the goal of the design, but also
//             proposed what they valued the most and the least.
//             <TrackVisibility style={{ display: 'inline' }}>
//               <AnimatedTextSpan
//                 text={`They value the organization and flexibility in the first solution, social support for the second and the fun for the last idea.`}
//               ></AnimatedTextSpan>
//             </TrackVisibility>
//             At the same time, our future solution needs to reduce their concerns
//             on <strong> privacy and the feeling of cheating</strong>. We decided
//             to come up with a new idea combined with all we learnt.
//           </BodyText1>
//         </Col>
//         <Col md={1}></Col>

//         <Col
//           md={{ size: 6, offset: 5 }}
//           style={{ ...styleForColumn, marginTop: '2rem' }}
//         >
//           <Subtitle1OpenSansWithLightColor style={{ marginBottom: '1rem' }}>
//             Birth of Gift Lab
//           </Subtitle1OpenSansWithLightColor>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             We saw the potential to fit all of those values in a game idea
//             called <strong>Gift Lab</strong>.
//             <TrackVisibility style={{ display: 'inline' }}>
//               <AnimatedTextSpan
//                 text={`In this game, gift buyers can post the icon or photo on gift recipients’ photos to guess their preference. At the end, the gift recipients will rate those ideas. Based on the feedback, gift buyers can have a better idea of what they want. `}
//               ></AnimatedTextSpan>
//             </TrackVisibility>
//             This idea provides users with both the context to inspire gift buyer
//             and social interaction that can reduce the stress.
//           </BodyText1>
//         </Col>

//         <Col md={1}></Col>

//         <Col
//           md={{ size: 4, offset: 1 }}
//           style={{ ...styleForColumn, marginTop: '2rem' }}
//         ></Col>

//         <Col md={{ size: 6 }} style={{ ...styleForColumn, marginTop: '2rem' }}>
//           <Subtitle1OpenSansWithLightColor style={{ marginBottom: '1rem' }}>
//             User flow
//           </Subtitle1OpenSansWithLightColor>
//           <BodyText1 style={{ marginBottom: '2rem' }}>
//             Around the core mechanism of the game, we thought about how users
//             will enter the game, and what information they require to to play
//             the game , and what information they need to better interpret the
//             results and make decisions. We add another 4 features to improve the
//             user experience.
//           </BodyText1>

//           <ul>
//             <li>
//               {' '}
//               <BodyText2> Profile builder</BodyText2>{' '}
//             </li>
//             <li>
//               {' '}
//               <BodyText2> Style quiz </BodyText2>{' '}
//             </li>
//             <li>
//               {' '}
//               <BodyText2> Forum </BodyText2>{' '}
//             </li>
//             <li>
//               {' '}
//               <BodyText2> Gift recommendation list </BodyText2>{' '}
//             </li>
//           </ul>
//         </Col>

//         <Col md={1}></Col>

//         <Col
//           md={{ size: 8, offset: 2 }}
//           style={{ ...styleForColumn, marginTop: '2rem' }}
//         >
//           <ZoomableImageWithModal image={userFlow} header={'User Flow'} />
//         </Col>

//         <div
//           style={{
//             height: '80%',
//             width: '10px',
//             background: 'transparent',
//             position: 'absolute',
//             top: '-20px',
//           }}
//           ref={ref}
//         />
//       </Row> */}
