import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import {displayFont, displayFontWeight, textFont, textFontWeight}  from '../site_style'

import {H1, H2, H3, H4, Subtitle1, Subtitle1OpenSans, Subtitle2, Subtitle2OpenSans, H5, BodyText1, BodyText2, Caption, Subtitle1OpenSansWithLightColor}  from '../text_style'

const mainColor = "#21BBA3"



const BPresentH1 = styled(H1)`
    color:${mainColor};
    letter-spacing: -1px;
    font-size: 4.5rem;
    font-weight: 500;
` 

const BPresentH2 = styled(H2)`

`

const BodyTextWithLightColor = styled(BodyText1)`
    line-height: 200%;
    color: #696969;
`

const BodyTextWithBold = styled(BodyText1)`
    font-weight: 600;
`

const OriginalH4 = H4

const BPresentH4 = ({children, showDot=true}) =>{
    
    return (
        <React.Fragment>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}> 
                <H3>{children}</H3>
                {
                    showDot ? <div style={{width:"10px", height:"10px",marginLeft:"12px", borderRadius:"50%", backgroundColor:mainColor}}>

                              </div> : <React.Fragment></React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const BodyTextWithDoubleSpace = styled(BodyText1)`
    line-height:200%;   
`

const marginVertical = 50
const gutter = 44
const paddingOneSide = 22

const styleForColumn = 
    {display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}

export {mainColor, BPresentH1,BodyTextWithDoubleSpace as BodyText1 ,
    BodyTextWithBold,
    BodyTextWithLightColor,
    marginVertical, Subtitle1, OriginalH4, H3, BPresentH4 as H4, gutter, BodyText2, paddingOneSide, styleForColumn, Caption, H5, Subtitle1OpenSansWithLightColor}

