import React, { useState, useRef, useEffect } from 'react'
import {
  Row,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'

import styled from 'styled-components'
// import sectionSeperator from ""
import { getColOf3 } from '../text_style'
import {
  ZoomableImage,
  ZoomableImageWithModal,
  AnimatedText,
  AnimatedTextSpan,
  Loader,
} from '../site_common_components'
import LazyLoad from 'react-lazyload'

export const SectionSeperator = props => {
  return (
    <Row className={props.className}>
      <Col md={5} />
      <Col
        md={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <SeperatorCircle />
          <SeperatorCircle />
          <SeperatorCircle /> */}

        <div
          style={{ height: 1, backgroundColor: '#D25709', width: '50%' }}
        ></div>
      </Col>
    </Row>
  )
}

const BPresentSectionSeperator = styled(SectionSeperator)`
  margin-top: 300px;
  margin-bottom: 150px;
`

const ZoomableImageWithModalLazy = ({
  style,
  image,
  header,
  imgStyle = {},
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggle = () => setModalOpen(!modalOpen)

  // src={image}
  return (
    <Container style={style}>
      <LazyLoad offset={100}>
        <ZoomableImage
          alt={header}
          src={image}
          style={imgStyle}
          className={'img-fluid'}
          onClick={toggle}
        />
      </LazyLoad>
      <Modal isOpen={modalOpen} size="xl">
        <ModalHeader toggle={toggle}>{header} </ModalHeader>
        <ModalBody>
          <Container className="d-flex justify-content-center">
            <img src={image} className={'img-fluid'} />
          </Container>
        </ModalBody>
      </Modal>
    </Container>
  )
}

export {
  BPresentSectionSeperator,
  ZoomableImage,
  ZoomableImageWithModalLazy as ZoomableImageWithModal,
  AnimatedText,
  AnimatedTextSpan,
  Loader,
}
