import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./bpresent_style"
import gift from '../../images/bpresentv2/gift.png'
import { useInView } from 'react-intersection-observer'
import TrackVisibility from 'react-on-screen';
import {ZoomableImageWithModal, AnimatedTextSpan} from "./bpresent_common_component"

export const BPresentProblemStatement = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.1,
        // root:""
    })

    return (
        <React.Fragment>
            <Row style={{
                top:"40px", zIndex:3, background:"transparent", 
                // position: "sticky" , 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
                <Col md={{size:4, offset:4}} style={{...styleForColumn, alignItems:'center'}}>
                    <H4 showDot={false} style={{background:"white", borderRadius: "8px",  position:"relative", padding:"4px", width: "fit-content"}}>
                        Problem Statement
                    </H4>
                </Col>
            </Row>

            <Row style={{position:"relative"}}>
                <Col md={{size:8, offset: 2}} style={{...styleForColumn,alignItems:"center" }}>

                    <BodyText1 style={{marginTop:"6rem", marginBottom:"2rem",fontSize:"24px", textAlign:"center"}}>
                        How can we help gift buyers find good gifts for people they feel close to?   
                    </BodyText1>

                    <img src={gift} style={{borderRadius:"10px"}} width="20%">
                    </img>


                    {/* <BodyText1 style={{marginTop:"2rem"}}>
                        Are you considering what gifts can surprise your closest friends and at the same show your love to them?  Gifts are commonly used to show people’s care to their close relationsips in various situations, such as birthday, anniversary, or tradional holiday. However, deciding the best gifts can be struggling. Thus, we decided to 
                        <TrackVisibility style={{display:"inline"}}>
                            <AnimatedTextSpan text={"design solutions for gift buyers who seek the best gifts for their close relations."}>
                            </AnimatedTextSpan>
                        </TrackVisibility>
                    
                    </BodyText1> */}
                </Col>

                <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
            </Row>

        </React.Fragment>
    )
}