import React, {useState} from 'react'
import {Row, Col, Container,  Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption} from "reactstrap"
import {BPresentH1, Subtitle1, H4, H5, gutter, BodyText1, BodyTextWithLightColor, paddingOneSide, styleForColumn} from "./bpresent_style"
import {ZoomableImageWithModal} from "./bpresent_common_component"
import persona from '../../images/bpresentv2/new_persona.png'
import researchPhoto from '../../images/bpresentv2/research-cropped.jpg'
import { useInView } from 'react-intersection-observer'
import userJourneyMap from "../../images/bpresentv2/User_journey_map.png"

import giftTabFeedback from "../../images/bpresentv2/ScreenFeedback/Gifting-page-feedback.png"
import addHintFeedback from "../../images/bpresentv2/ScreenFeedback/Add-hint-feedback.png"
import friendsPageFeedback from "../../images/bpresentv2/ScreenFeedback/Friends-page-feedback.png"
import homePageFeedback from "../../images/bpresentv2/ScreenFeedback/Home-page-feedback.png"
import leaderboardFeedback from "../../images/bpresentv2/ScreenFeedback/Leaderboard-feedback.png"
import profileFeedback from "../../images/bpresentv2/ScreenFeedback/Profile-feedback.png"
import profileSetupFeedback from "../../images/bpresentv2/ScreenFeedback/Profile-setup-feedback.png"
import styleTestFeedback from "../../images/bpresentv2/ScreenFeedback/Style-test-feedback.png"

import { Caption } from '../text_style'

export const BPresentEvaluation = () => {
    const items = [
        {src: giftTabFeedback, altText:"Feedback for gift tab"},
        {src: addHintFeedback, altText: "Feedback for adding hint feedback"},
        {src: friendsPageFeedback, altText:"Feedback for adding friend page"},
        {src: homePageFeedback, altText: "Feedback for home page design"},
        {src: leaderboardFeedback, altText: "Feedback for the leaderboard"},
        {src: profileFeedback, altText: "Feedback for the profile"},
        {src: profileSetupFeedback, altText: "Feedback for setting up the profile"},
        {src: styleTestFeedback, altText:"Feedback for style test"}
    ]
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
              <div style={{padding:"10px", height:"450px", display:"flex", justifyContent:"center"}}>
                <img src={item.src} alt={item.altText} style={{height:"100%"}}/>
              </div>
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
    });

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
            <Row style={{
                zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in",
                marginBottom:"2rem"
            }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4>
                        Evaluation
                    </H4>
                </Col>
            </Row>
            
            <Row style={{marginBottom: "300px"}}>
                <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    <BodyText1>
                        To explore any usability issues and how people feel about the tools, we sought feedback from two different sources, <strong> experts and users</strong>. 
                    </BodyText1>
                </Col> 
            </Row>

            <Row >
                <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    <H5 style={{marginBottom:"2rem"}}>
                        Expert huristic evaluation
                    </H5>

                    <BodyText1>
                        We recruited two UX experts for heuristic evaluation sessions, based on Nielson’s 10 principles.
                    </BodyText1>

                    <BodyTextWithLightColor>
                        1: Some screens contain <strong> confusing </strong> system status, where they couldn’t understand
                        <br></br>
                        2: The wording of certain <strong>functionalites</strong> do not explain them well
                        <br></br>
                        3: The interaction game <strong>doesn’t have enough documentation</strong>.
                    </BodyTextWithLightColor>
                </Col>
            </Row>

            <Row style={{marginTop:"6rem", marginBottom:"300px"}}>
                <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    <H5 style={{marginBottom:"2rem"}}>
                        Usability testing reveals deeper usability problems
                    </H5>

                    <BodyText1>
                    With some tweaks, we looked for 3 users with more gift buying experience to each completed 11 tasks as both gift buyer and recipient. They then answered SUS or subjective questions.
                    </BodyText1>

                    <BodyTextWithLightColor>
                        1: The <strong>medicore usability score</strong> became the main barrier for users to understand the value of the app
                        <br/>
                    2: The value of the application is not well understood due to the <strong>overcomplicated features</strong>
                        <br/>
                    3: The interaction flow between many features is <strong> confusing and unintuitive </strong>
                        <br/>
                    4: The game is not <strong> self-explanatory </strong> enough for them to try the game on their own and see its value even after they know how to play the game
                    </BodyTextWithLightColor>
                </Col>
            </Row>

            <Row style={{marginBottom:"0px"}}>
            <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    <H5 style={{marginBottom:"4rem"}}>
                        Consolidate feedback onto screens
                    </H5>
                    
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                            {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
                    </Carousel>

                </Col>
            </Row>

        </React.Fragment>
    )
}

// <Row style={{marginTop: "1.5rem", position:"relative"}}>
                
//                 <Col md={{size:6, offset: 5}}>
//                     <BodyText1 style={{marginBottom:"2rem"}}>
//                         To explore any usability issues and how people feel about the tools, we sought feedback from two different sources, <strong> experts and users </strong>.     
//                     </BodyText1>

//                     <BodyText1 style={{marginBottom:"2rem"}}>
//                     First, before we presented our designs to users, we recruited two UX experts for <strong>heuristic evaluation </strong> sessions, based on Nielson’s 10 principles. They pointed out:

//                     <ul style={{paddingTop:"1rem"}}>
//                         <li style={{marginBottom:"0"}}>
//                         some screens contain <strong>confusing system status</strong>, where they couldn’t understand
//                         </li>
//                         <li style={{marginBottom:"0"}}>
//                         the <strong>wording </strong> of certain functionalites do not explain them well
//                         </li>
//                         <li style={{marginBottom:"0"}}>
//                         the game doesn’t have <strong> enough documentation</strong>.  
//                         </li>
//                     </ul>
//                     </BodyText1>


//                     <BodyText1 style={{marginBottom:"2rem"}}>
                        
//                         After some tweaks, we looked for 3 users with more gift buying experience to complete <strong> usability testing </strong> sessions. Each completed 11 tasks as both gift buyer and recipients and then answered SUS or subjective questions. The medicore usability score (60 / 100) became the main barrier for users to understand the value of the app. Users reported that 
                        
//                         <ul style={{paddingTop:"1rem"}}>
//                             <li style={{marginBottom:"0"}}>
//                             the value of the application is not well understood due to the <strong> overcomplicated features </strong>  
//                             </li>
//                             <li style={{marginBottom:"0"}}>
//                             the <strong>interaction flow</strong> between many features is confusing and unintuitive
//                             </li>
//                             <li style={{marginBottom:"0"}}>
//                             the game is <strong>  not self-explanatory </strong> enough for them to try the game on their own and see its value even after they know how to play the game
//                             </li>
//                         </ul>
//                     </BodyText1>
//                 </Col>    
//                 <Col md={1}/>

//                 {/* New Row */}
//                 <Col md={{size:6, offset:3}} style={{display:"flex", flexDirection:"column"}}>
//                     <Carousel
//                         activeIndex={activeIndex}
//                         next={next}
//                         previous={previous}
//                     >
//                         <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
//                             {slides}
//                         <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
//                         <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
//                     </Carousel>

//                     <Caption style={{alignSelf:"center"}}>
//                         Feedback for different screens 
//                     </Caption>
//                 </Col>
//                 <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>


//             </Row>