import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./bpresent_style"
import { useInView } from 'react-intersection-observer'

export const BPresentLesson = () => {
    
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
            <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}> 
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                        What I learnt
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position:"relative"}}>
                <Col md={{size: 6, offset: 5}} style={styleForColumn}>
                    <BodyText1>
                        <ul>
                            <li>
                                Being <strong> specific </strong> about the design goal and users will not make the project boring or limit the project. An inadequate user research describing a generic user group will. A specific user group shares many interesting design challenges that we were not aware of.
                            </li>
                            
                            <li>
                                Be cautious about <strong> “Feature creep” </strong>. We threw too many features, leading to important features less polished and defined. Understanding the user constraints are guidelines for making decisions on necessary features and micro-interactions.
                            </li>

                            <li>
                                Constant communication with users is necessary for a UX project. Each phase of UX process requires their input. User research is more than initial research.
                            </li>

                            <li>
                                Understand why users like a design alternative rather than which one they like. Users could have different reasons for their prefences from designers’ think. 
                            </li>

                            <li>
                                Creating a user flow and information architecture is important for structuring interaction design. It is easy to lose track of what information is necessary or not during the design.
                            </li>
                        </ul>
                    </BodyText1>
                </Col>

                <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
            </Row>

        </React.Fragment>
    )
}