import React from 'react'
import {Row, Col, Container} from "reactstrap"
import indicator from '../../images/hci_foundation/Indicator.svg'
import indicator2 from '../../images/hci_foundation/Indicator2.svg'
import indicator3 from '../../images/hci_foundation/Indicator3.svg'
import indicator4 from '../../images/hci_foundation/Indicator4.svg'
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, mainColor, styleForColumn } from "./bpresent_style"
import {getSeperatorWithHeight} from "../text_style"
import styled from "styled-components"
import {textFont, textFontWeight, displayFont, displayFontWeight} from "../site_style"

let StyledLine = styled.div`

  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  text-align: left;
`

let StepHeader = styled.div`

  font-family: ${displayFont};
  font-size: 24px;
  line-height: 34px;
  color: ${mainColor};
  text-align: left;
`


let contentList1 = ["Competitive analysis",
  "Survey",
  "User interview",
  "Task analysis",
  "Persona",
  "User journey map"]

let contentList2 = ["Sketching",
  "Wireframing",
  "Storyboard",
  "User interview"]

let contentList3 = [
  "Paper Prototype",
  "Information architecture",
  "High-fidelity Prototype",
  "Adobe XD",
  "Principle"
]


let contentList4 = [
  "Heuristic evaluation",
  "User usability testing",
]


const Step = (props) => {

    return(
        <div>
            <StepHeader> {props.header} </StepHeader>

            <img src={props.indicator} style={{height:"6px", marginBottom:`5px`}} className="img-fluid" alt="Responsive image"/>

            {props.contentList.map(d => (<StyledLine> {d} </StyledLine>) )}
        </div>
    )

}

export const BPresentProcess = (props) => {

    const data = {
        header: "Process",
        subtitle: "Follow the design thinking and process"
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={{size:4, offset:1}} style={styleForColumn}> 
                    <H4>
                        Process
                    </H4>
                </Col>
                {/* <Col md={6}> <SectionIntroHeader header={data.header} subtitle={data.subtitle}  /> </Col> */}
            </Row>

            {getSeperatorWithHeight (30)}

            <Row>
                <Col md={{size:10, offset:1}} style={{ ...styleForColumn, display:"flex", flexDirection:"row" , justifyContent:"space-between"}}> 
                    <Step header={"Research"} contentList={contentList1} indicator={indicator} /> 
                    <Step header={"Ideation"} contentList={contentList2} indicator={indicator2} /> 
                    <Step header={"Prototype"} contentList={contentList3} indicator={indicator3} />
                    <Step header={"Evaluation"} contentList={contentList4} indicator={indicator4} />
                </Col>
            </Row>
        </React.Fragment>
    )
}
