import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./bpresent_style"
import { useInView } from 'react-intersection-observer'

export const BPresentDesignGoal = () => {
    
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })
    return (
        <React.Fragment>
            <Row style={{
                top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.35s ease-in", opacity: inView? 1 : 0
            }}> 
                <Col md={{size:4, offset:4}} style={{...styleForColumn, alignItems:"center", marginBottom:"2rem"}}>
                    <H4 showDot={false}>
                        Design Goal
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position:"relative", transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0}}>
                <Col md={{size: 6, offset:3}} style={styleForColumn}>
                    <BodyText1 style={{textAlign:"center", lineHeight:"200%"}}>
                        We aim to design a <strong>less stressful</strong> software experience to <strong>inspire</strong> users’ to choose high quality gift ideas that meet their gift recipients’ need and want. 
                    </BodyText1>
                </Col>

                <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
            </Row>

        </React.Fragment>
    )
}