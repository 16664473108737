import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {BPresentH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn, BodyTextWithBold, BodyTextWithLightColor} from "./bpresent_style"
import {ZoomableImageWithModal, AnimatedTextSpan} from "./bpresent_common_component"
import persona from '../../images/bpresentv2/new_persona.png'
import researchPhoto from '../../images/bpresentv2/research-cropped.png'
import { useInView } from 'react-intersection-observer'
import userJourneyMap from "../../images/bpresentv2/User_journey_map.png"
import competitiveAnalysis from "../../images/bpresentv2/competitive-analysis.png";
import TrackVisibility from 'react-on-screen';


export const BPresentResearch = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })

    const [ref2, inView2, entry2] = useInView({
        /* Optional options */
        threshold: 0.4,
    })

    const [ref3, inView3, entry3] = useInView({
        /* Optional options */
        threshold: 0.2,
    })
    

    return (
        <React.Fragment>
            <div ref={ref}>
            <Row style={{
                // position: "sticky" , 
                zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", 
                opacity: inView? 1 : 0
            }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4>
                        Research
                    </H4>
                </Col>

                <Col md={{size: 7}}></Col>

                <Col md={{size:10, offset:1}} style={styleForColumn}>
                    <BodyTextWithBold style={{marginTop:"1rem", marginBottom:"1rem"}}>
                        Interview, Survey, Task analysis, Competitive analysis
                    </BodyTextWithBold>

                    <BodyTextWithLightColor>
                        Our research plan aimed to understand the gift buyers’ motivation, habits and their gift buying process. In a tight schedule of 1 week, we interviewed 15 young people who usually sent gifts to deeply understand their process of buying gifts. It is then followed by a survey to validate those patterns among 100 students quickly. Aside, we also spent time in analyzing user tasks to describe gift buying' process. Our interests in understanding how people currently buy gifts led us to exploring the playing field more through competitive analysis. 
                    </BodyTextWithLightColor>
                </Col>

            </Row>
            </div>

            <Row style={{height:"300px"}}></Row>
            <Row style={{marginTop: "1.5rem", position:"relative", 
                transition: "opacity 0.2s ease-in", opacity: 1 
                }}>
                <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    {/* <img src={researchPhoto} style={{alignSelf:"flex-end", width: "65%", marginBottom:"2rem"}}/>  */}

                    {/* <BodyText1 style={{marginBottom:"3rem"}}>
                        <TrackVisibility style={{display:"inline"}}>
                            <AnimatedTextSpan text={"Our primary users are those who would love use technollgies to buy gifts to their close relationships."}>
                            </AnimatedTextSpan>
                        </TrackVisibility>
                        Our research plan aims to understand their motivation, habits and gift buying process. In a tight schedule of 1 week, we <strong> interviewed </strong> 15 people to deeply understand their process of buying gifts, followed by a <strong> survey </strong> to validate those patterns among 100 students quickly. Our interest in understanding how people currently buy gifts lead us to exploring the play field more through <strong>competitive analysis</strong>. 
                    </BodyText1> */}

                   
                    <div ref={ref2} style={{transition: "opacity 0.2s ease-in", 
                opacity: inView2? 1 : 0}}>
                    <BodyTextWithBold style={{marginBottom:"1rem"}} >
                        Pain point 1: Users want to know more about their close relation’s gift preference
                    </BodyTextWithBold>

                    <BodyTextWithLightColor style={{marginBottom:"4rem"}}>
                        Our target users want to send out a gift that meets need and wants of their close relations as a symbol to show love to their loved ones. This gift giving behavior is quite universal for all important days like birthday, anniversary and Christmas.
                    </BodyTextWithLightColor>

                    <BodyTextWithBold style={{marginBottom:"1rem"}}>
                        Pain point 2:  Most of our target users feel overwhelmed for deciding what to buy from many options online
                    </BodyTextWithBold>

                    <BodyTextWithLightColor style={{marginBottom:"4rem"}}>
                        Our task analysis reveals 4 stages and related user tasks in gift buying process: when to buy, what to buy, purchase & delivery and after purchasing. We also noticed their current strategies for easing their concern: recalling their friends’ hobbies from their previous conversation, asking friends, and sking help from reddit. 
                    </BodyTextWithLightColor>

                    <BodyTextWithBold style={{marginBottom:"1rem"}}>
                        Pain point 3: Few solution is designed to help gift buyers overcome the gift ideations in their process
                    </BodyTextWithBold>

                    <BodyTextWithLightColor style={{marginBottom:"300px"}}>
                        Most websites, similar to Amazon Wishlist focus on selling their own products to the users without trying to help gift buyers identify a good gift based on the gift recipients. Their large confusing collection of gift options sometimes also adds stress to users.
                    </BodyTextWithLightColor>
                    </div>

                    <div  ref={ref3} style={{transition: "opacity 0.2s ease-in", 
                opacity: inView3? 1 : 0 }}>
                    <BodyTextWithBold style={{marginBottom:"2rem"}}>
                        Communicate our findings through persona, user journey map and competitive analysis graph
                    </BodyTextWithBold>

                    <Container style={{padding:0}}>
                    <Row>
                        <Col md={{size: 4}} >
                            <ZoomableImageWithModal image={userJourneyMap} style={{padding:0, borderStyle:"solid", borderColor:"rgba(151,151,151,0.2)", borderWidth:"1px", borderRadius:"12px"}} header={"User Journey Map"}/>
                        </Col>

                        <Col md={{size: 4}} >
                            <ZoomableImageWithModal image={competitiveAnalysis} style={{padding:0, borderStyle:"solid", borderColor:"rgba(151,151,151,0.2)", borderWidth:"1px", borderRadius:"12px"}}  header={"Competitive Analysis"}/>
                        </Col>

                        {/* New Row */}
                        <Col md={{size:4}} style={styleForColumn}>
                            {/* <ZoomableImageWithModal image={competitiveAnalysis} header={"Competitive Analysis"}/> */}
                            <ZoomableImageWithModal image={persona} style={{padding:0}} header={"Gift Buyer Persona"}/>
                        </Col>

                    </Row>
                    </Container>
                    </div>

                </Col>

                <Col md={1}/>
               
                {/* New Row */}
                {/* <Col md={{size: 4, offset: 1}} style={styleForColumn}>
                        <ZoomableImageWithModal image={persona} header={"Gift Buyer Persona"}/>
                </Col> */}
                
                {/* New Row */}
                {/* <Col md={{size:4, offset: 1}} style={styleForColumn}>
                        <ZoomableImageWithModal image={competitiveAnalysis} header={"Competitive Analysis"}/>
                </Col> */}

                {/* Hardest thing is to integrate the accordance here */}
                {/* <Col md={{size: 6}} style={styleForColumn}>
                    <BodyText1>
                        However, <strong> few website online </strong> is designed to help gift buyers overcome the gift ideations in this process in their minds. Most websites, similar to Amazon Wishlist focus on selling their own products to the users without trying to help gift buyers identify a good gift based on the gift recipients. Their large confusing collection of gift options sometimes also adds stress to users.
                    </BodyText1>
                </Col>
                */}
                {/* <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>  */}
            </Row>

        </React.Fragment>
    )
}


export const BPresentResearch2 = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    const [ref2, inView2, entry2] = useInView({
        /* Optional options */
        threshold: 0,
      })

    return (
        <React.Fragment>
            <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                        Research
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position:"relative"}}>
                <Col md={{size: 6, offset: 5}} style={styleForColumn}>
                    <img src={researchPhoto} style={{alignSelf:"flex-end", width: "65%", marginBottom:"2rem"}}/> 

                    <BodyText1 style={{marginBottom:"3rem"}}>
                        <TrackVisibility style={{display:"inline"}}>
                            <AnimatedTextSpan text={"Our primary users are those who would love use technollgies to buy gifts to their close relationships."}>
                            </AnimatedTextSpan>
                        </TrackVisibility>
                        Our research plan aims to understand their motivation, habits and gift buying process. In a tight schedule of 1 week, we <strong> interviewed </strong> 15 people to deeply understand their process of buying gifts, followed by a <strong> survey </strong> to validate those patterns among 100 students quickly. Our interest in understanding how people currently buy gifts lead us to exploring the play field more through <strong>competitive analysis</strong>. 
                    </BodyText1>

                </Col>

                <Col md={1}/>
               
                {/* New Row */}
                {/* <Col md={{size: 4, offset: 1}} style={styleForColumn}>
                        <ZoomableImageWithModal image={persona} header={"Gift Buyer Persona"}/>
                </Col> */}

                <Col md={{size:6, offset: 5}} style={styleForColumn}>
                    <BodyText1 style={{marginBottom:"3rem"}}>
                        
                    As our research result, we found out that our target users want to send out a gift that meets need and wants of their close relations as a symbol to show love to their loved ones. Thus, one of their main <strong> user need </strong>
                    <TrackVisibility style={{display:"inline"}}>
                        <AnimatedTextSpan text={"is to know more about their close relation’s gift preference"}>
                        </AnimatedTextSpan>
                    </TrackVisibility>
                    This gift giving behavior is quite universal for all important days like birthday, anniversary and Christmas.
                    </BodyText1>
                </Col>

                <Col md={1}/>

                <Col md={{size: 6, offset: 3}}>
                    <ZoomableImageWithModal image={persona} header={"Gift Buyer Persona"}/>
                </Col>

                {/* New Row */}
                {/* <Col md={{size: 4, offset: 1}} style={styleForColumn}>
                    <ZoomableImageWithModal image={userJourneyMap} header={"User Journey Map"}/>
                </Col> */}

                <Col md={{size:6, offset: 5}} style={styleForColumn}>
                    <BodyText1 style={{marginBottom:"3rem"}}>
                        Our task analysis also reveals 4 stages and related user tasks in gift buying process: when to buy, what to buy, purchase and delivery and after purchasing. 
                        One <strong>pain point</strong> we discovered is that
                        <TrackVisibility style={{display:"inline"}} >
                            <AnimatedTextSpan text={"currently, most of our target users feel overwhelmed for deciding what to buy from many options online"}>
                            </AnimatedTextSpan>
                        </TrackVisibility>
                        . We also noticed their current strategies for easing their concern: recalling their friends’ hobbies from their previous conversation, asking friends, and sking help from reddit. 
                    </BodyText1>
                </Col>

                <Col md={1}/>
                
                {/* New Row */}
                <Col md={{size: 6, offset: 3}}>
                <ZoomableImageWithModal image={userJourneyMap} header={"User Journey Map"}/>
                </Col>
                
                {/* New Row */}
                <Col md={{size:4, offset: 1}} style={styleForColumn}>
                        <ZoomableImageWithModal image={competitiveAnalysis} header={"Competitive Analysis"}/>
                </Col>

                {/* Hardest thing is to integrate the accordance here */}
                <Col md={{size: 6}} style={styleForColumn}>
                    <BodyText1>
                        However, <strong> few website online </strong> is designed to help gift buyers overcome the gift ideations in this process in their minds. Most websites, similar to Amazon Wishlist focus on selling their own products to the users without trying to help gift buyers identify a good gift based on the gift recipients. Their large confusing collection of gift options sometimes also adds stress to users.
                    </BodyText1>
                </Col>
            
                {/* <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/> */}
            </Row>

        </React.Fragment>
    )
}

