import React, { useState } from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'

import SEO from '../components/seo'
import Footer from './footer'
import { Row, Container } from 'reactstrap'
import { getSeperatorWithHeight } from '../components/text_style'
import { Layout, StyledContainer } from '../components/layout'
import { BPresentSectionSeperator } from '../components/bpresentv2/bpresent_common_component'
import { BPresentIntro } from '../components/bpresentv2/bpresent_intro'
import { BPresentProcess } from '../components/bpresentv2/bpresent_process'
import { BPresentProblemStatement } from '../components/bpresentv2/bpresent_problem_statement'
import { BPresentResearch } from '../components/bpresentv2/bpresent_research'
import { BPresentDesignGoal } from '../components/bpresentv2/bpresent_design_goal'
import { BPresentIdeation } from '../components/bpresentv2/bpresent_ideate'
import { BPResentPrototype } from '../components/bpresentv2/bpresent_prototype'
import { BPresentHifi } from '../components/bpresentv2/bpresent_hifi_prototype'
import { BPresentEvaluation } from '../components/bpresentv2/bpresent_evaluation'
import { BPresentImprovement } from '../components/bpresentv2/bpresent_redesign'
import { BPresentContribution } from '../components/bpresentv2/bpresent_contribution'
import { BPresentLesson } from '../components/bpresentv2/bpresent_lession'
import Scrollspy from 'react-scrollspy'

const BPresentV2 = () => {
  const resourceLoaded = useState(false)

  return (
    <Layout>
      <SEO title="B-Present" />

      <Scrollspy
        items={[
          'overview',
          'problem_statement',
          'research',
          'design_goal',
          'ideation',
          'prototype',
          'evaluation',
          'improvement',
          'lesson',
        ]}
        style={{
          position: 'fixed',
          top: '150px',
          paddingTop: '48px',
          listStyleType: 'none',
        }}
        currentClassName={'is-current-bpresent'}
        scrolledPastClassName={'is-past-bpresent'}
        offset={-30}
        className={'sidebar'}
      >
        <li className={'scollliv2'}>
          <a href="#">Overview</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#problem_statement">Problem Statement</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#research">Research</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#design_goal">Design Goal</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#ideation">Ideation</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#prototype">Prototype</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#evaluation">Evaluation</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#improvement">Improvement</a>
        </li>
        <li className={'scollliv2'}>
          <a href="#lesson">Lesson</a>
        </li>
      </Scrollspy>

      {getSeperatorWithHeight(50)}
      <Container
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          maxWidth: '1350px',
          alignSelf: 'center',
        }}
      >
        <section id={'overview'}>
          <BPresentIntro />
        </section>

        {/* <BPresentContribution/>

                <BPresentSectionSeperator/> */}

        {/* <BPresentProcess/>

                <BPresentSectionSeperator/> */}

        <section id={'problem_statement'}>
          <BPresentSectionSeperator />

          <BPresentProblemStatement />
        </section>

        <section id={'research'}>
          <BPresentSectionSeperator />

          <BPresentResearch />
        </section>

        <section id={'design_goal'}>
          <BPresentSectionSeperator />

          <BPresentDesignGoal />
        </section>

        <section id={'ideation'}>
          <BPresentSectionSeperator />

          <BPresentIdeation />
        </section>

        <section id={'prototype'}>
          <BPresentSectionSeperator />

          <BPResentPrototype />

          <BPresentSectionSeperator />

          <BPresentHifi />
        </section>

        <section id={'evaluation'}>
          <BPresentSectionSeperator />

          <BPresentEvaluation />
        </section>

        <section id={'improvement'}>
          <BPresentSectionSeperator />

          <BPresentImprovement />
        </section>

        <section id={'lesson'}>
          <BPresentSectionSeperator />

          <BPresentLesson />
        </section>

        {getSeperatorWithHeight(100)}

        {getSeperatorWithHeight(80)}
        <Row className="d-flex justify-content-center">
          <a style={{ color: `#ADADAD` }} href="#">
            Back to top
          </a>
        </Row>

        {getSeperatorWithHeight(20)}
        <Footer />
      </Container>
    </Layout>
  )
}

export default BPresentV2
